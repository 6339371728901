import React from "react"
import { css } from "@emotion/core"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import profilePic from "images/cathy_portfolio_pic.png"
import nextPageBtn from "images/next-page-button.png"
import linkedInIcon from "images/linkedin-icon.png"
import githubIcon from "images/github-icon.png"
import { useTranslation } from "react-i18next"

import SEO from "components/seo"

const linkStyle = css`
  color: #273C57;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  position:relative;
`

const IndexPage = ({ ...context }) => {
  const { t, i18n } = useTranslation()

  return(
    <div css={css`
      background: #ae68bc;
      height: 100vh;
      display: flex;
      @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        padding-top: 80px;
      } 
    `}>
      <div css={css`
        margin-top: 40px;
        margin-right: 40px;
        background-color: white;
        width: 100%;
        padding: 3% 4% 2%;
        display: grid;
        overflow: auto;
        grid-template-columns: 55% 45%;
        grid-template-rows: 17% auto 20%;
        grid-row-gap: 30px;
        grid-template-areas: 
          "title title"
          "intro headshot"
          "next-page next-page";
        @media (min-width: 1500px) {
          grid-template-columns: 58% 42%;
        }
        @media (min-width: 320px) and (max-width: 480px) {
          margin: 0 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3% 6% 7%;
        } 
      `}>
        <SEO title="Home" />
        <h1 css={css`
          grid-area: title;
          align-self: end;
          @media (min-width: 320px) and (max-width: 480px) {
            font-size: calc(1em + 4vw);
            margin: 1.5rem auto;
          }
        `}>
          Cathy Huang
        </h1>
        <div css={css`
          grid-area: headshot;
          padding-left: 2vw;
          margin-bottom: 1.45rem;
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
          }
        `}>
          <img 
            src={profilePic}
            css={css`
              @media (min-width : 768px) {
                  width: 29vw;
                  max-width: 480px;
              }
              @media (min-width: 320px) and (max-width: 480px) {
                width: 70vw;
              }
            `}
            alt="Cathy Huang"
          />
        </div>
        <div css={css`
          grid-area: intro;
          padding-right: 0.5vw;
          padding-left: 2.1vw;
          @media (min-width: 1500px) {
            padding-right: 4vw;
          }
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 25px;
          }
        `}>
          <div css={css`
            box-shadow: 12px 10px 18px 3px rgba(0, 0, 0, 0.2);
            width: 55%;
          `}>
            <p css={css`
              background: #273C57;
              color: white;
              text-align: center;
              font-size: calc(1em + 1.1vw);
              font-style: italic;
            `}>
              {t('home.position')}
            </p>
          </div>
          <p css={css`
            text-align: justify;
            padding-top: 7%;
            font-size: 1.1rem;
          `}>
            {t('home.summary').split('\n').map(function(item, key) {
                return (
                  <span key={key}>
                    {item}
                    <br/>
                  </span>
                )
              })
            }
          </p>
          <br/>
          <div>
            <a
              href="https://linkedin.com/in/cathyhuangtw"
              target="_blank" rel="noopener noreferrer"
              aria-label="Check Cathy's LinkedIn profile"
            >
              <img
                css={css`
                  margin-right: 12px; 
                `}
                src={linkedInIcon}
                alt="Cathy's LinkedIn"
              />
            </a>
            <a
              href="https://github.com/CathyHuangtw"
              target="_blank" rel="noopener noreferrer"
              aria-label="Check Cathy's GitHub page about her projects"
            >
              <img
                src={githubIcon}
                alt="Cathy's GitHub"
              />
            </a>
          </div>
        </div>
        <div css={css`
          grid-area: next-page;
          align-self: start;
          padding-left: 2vw;
          @media (min-width: 320px) and (max-width: 480px) {
            padding-left: 0;
          }
        `}>
          <AniLink swipe direction="up" to="/experience"
            css={linkStyle}
          >
            <img
              src={nextPageBtn}
              alt="Next Page"
              css={css`
                filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.2));
              `}
            />
            <span css={css` 
              position: absolute;
              width: 150px;
              margin-top: 30%; 
            `}>02 {t('navbar.experience')}</span>
          </AniLink>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
